<template>
  <AppSource
    v-for="source in sources"
    :key="source.format"
    :data-src="`source-group-${source.srcset}`"
    v-bind="source"
  />
</template>
<script setup lang="ts">
import type { Srcset, SourceProp } from './AppSource.vue';

const props = withDefaults(
  defineProps<{
    srcset: Srcset;
    width?: string | null;
    height?: string | null;
    media?: string;
    formats: Array<'avif' | 'webp' | 'jpeg'>;
    auto?: boolean;
    fit?: 'cover' | 'contain' | 'fill';
    sizes?: string | null;
  }>(),
  {
    width: null,
    height: null,
    media: '',
    auto: false,
    formats: () => ['jpeg', 'webp', 'avif'],
    fit: 'cover',
    sizes: null,
  },
);

console.log(props, 'groupProps');
const sources: SourceProp[] = props.formats.map(format => {
  return {
    srcset: props.srcset,
    media: props.media,
    width: props.width,
    height: props.height,
    auto: props.auto,
    format,
    fit: props.fit,
    sizes: props.sizes,
  };
});
</script>
